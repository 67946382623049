import React from 'react'
import "./Dashboard.css"
import AdminProducts from "./dashboardComponents/AdminProducts"

function Dashboard() {
  return (
    <div>
       <h1>Dashboard</h1>
       <AdminProducts/>
    </div>
  )
}

export default Dashboard